<template>
  <div class="flex flex-col justify-center text-center max-w-lg mx-auto">
    <BaseIconMessage
      icon="fa-telescope"
      :message="$t('no_buildings_yet')"
      class="my-6"
    />
    <span
      class="tg-mobile-body-small md:tg-desktop-body text-gray-cc mb-6"
      v-text="$t('dashboard_properties_text')"
    />
    <router-link
      v-for="(link, index) in links"
      :key="index"
      :to="link.to"
      class="tg-mobile-link-small md:tg-desktop-link link mb-4"
    >
      <i :class="['fal mr-2.5', link.icon]" />
      {{ link.text }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'DashboardNoProperty',
  computed: {
    links() {
      return [
        // {
        //   to: { name: 'Searches' },
        //   text: this.$t('create_new_search'),
        //   icon: 'fa-bell-plus'
        // },
        // {
        //   to: { name: 'Newsletters' },
        //   text: this.$t('set_preferences_for_newsletters'),
        //   icon: 'fa-envelope'
        // },
        {
          to: { name: 'Profile' },
          text: this.$t('adjust_your_data'),
          icon: 'fa-user'
        }
        // {
        //   to: { name: 'Help' },
        //   text: this.$t('need_help'),
        //   icon: 'fa-life-ring'
        // }
      ];
    }
  }
};
</script>
