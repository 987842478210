<template>
  <div>
    <h2 class="tg-mobile-header-3 md:tg-desktop-header-3">
      {{ $t('welcome') }}, {{ getUserProfileName || $t('user') }}!
    </h2>
    <div v-if="properties.length" class="flex flex-col">
      <router-link
        v-for="(property, index) in properties"
        :key="index"
        :to="property.to"
        class="tg-mobile-link-small md:tg-desktop-link link"
        v-text="property.name"
      />
    </div>
    <DashboardNoProperties v-else />
  </div>
</template>

<script>
import DashboardNoProperties from '@/components/DashboardNoProperties';
import { mapGetters } from 'vuex';

export default {
  name: 'Dashboard',
  components: {
    DashboardNoProperties
  },
  computed: {
    ...mapGetters('profile', ['getUserProfileName', 'getUserLocale']),
    properties() {
      return [
        // We need to specify the locale param here since the locale param is required, and this is not a child route.
        // {
        //   to: {
        //     name: 'PropertyDetail',
        //     params: { propertyId: 1, locale: this.getUserLocale }
        //   },
        //   name: 'Property 1'
        // },
        // {
        //   to: {
        //     name: 'PropertyDetail',
        //     params: { propertyId: 2, locale: this.getUserLocale }
        //   },
        //   name: 'Property 2'
        // }
      ];
    }
  }
};
</script>
